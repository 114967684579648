/*  Color Palette for Proving Ground
 *  #4A553A - Sage 
 *  #D1B68F - Khaki
 *  #F3ECE2 - Linen
 *  #20190E - Dark Chocolate
 *  #A63D00 - Rust
 */

/*  Color Palette for Tower Ravens
 *  #090809 - Black
 *  #C41E3A - Red (Pantone 200)
 *  #FFFFFF - White
 */


/*  Universal style for links.  */

/*  Unvisited  */
a:link {
  color: #4A553A;     /*  Sage  */
}

/*  Visited  */
a:visited {
  color: #4A553A;     /*  Sage  */
}

/*  Mouse Over  */
a:hover {
  color: #A63D00;     /*  Rust  */
}

/*  Selected  */
a:active {
  color: #A63D00;     /*  Rust  */
}


/*  Universal Style for Tabs in Tabpane.  */

/*  Inactive Tabs  */
.customClass > a {
  margin: 1px 4px 1px 0px;
  color: #D1B68F;                          /*  Desert Tan  */
  background-color: #4A553A;               /*  Sage  */
}

/*  Hover Over Tabs  */
.customClass > a:hover {
  margin: 1px 4px 1px 0px;
  color: #20190E !important;               /*  Dark Chocolate  */
  background-color: #A63D00 !important;    /*  Rust  */
}

/*  Active Tabs  */
.customClass > a.active {
  margin: 1px 4px 1px 0px;
  color: #4A553A !important;               /*  Sage  */
  background-color: #D1B68F !important;    /*  Desert Tan  */
}

/*  Close X mark.  */
/*  MWX -- Changing background-color works, but I cannot change the 
    MWX -- close icon.  */
.bs-remove-tab {
  color: #20190E !important;               /*  Dark Chocolate  */
  content: "X"
}


/*  Universal Style for Buttons.  */

button:hover {
  color: #20190E !important;
  background-color: #A63D00 !important;
}

button {
  background-color: #20190E !important;
  color: #D1B68F !important;
}

.mwx-btn-inv {
  background-color: #D1B68F !important;
  color: #4A553A !important;
}

/*  Universal Style for Modals.  */

.modal-header {
  font-variant: small-caps;
  background-color: #4A553A; 
  color: #D1B68F;
}

.modal-body {
  background-color: #D1B68F; 
  color: #4A553A;
}

.modal-footer {
  background-color: #4A553A; 
  color: #D1B68F;
}

.modal-input {
  background-color: #F3ECE2; 
  color: #20190E;
}

/*  Universeal Style for Text Frame on Transparent Background.  */

.mwx-text-box {
  border-radius: 5px;
  margin: 10px 10px 15px 10px;
  padding: 10px;
  background-color: #F3ECE2;
  color: #20190E;
}

/*  Unviersal Style for Accordion  */

.card-header {
  background-color: #4A553A;
  color: #D1B68F;
}

.card-header:hover {
  background-color: #A63D00;
  color: #20190E;
}

.card {
  background-color: #D1B68F;
  color: #4A553A;
  border: 0;
  padding: 0;
  margin: 0;
}

hr {
  border-top: 2px solid #20190E;
}

